import React from "react"
import Separator from "../../Separator"
import "./style.scss"

const Title = ({ title }) => {
  return (
    <div className="section-header">
      <Separator />
      <h4 className="is-size-4">{title}</h4>
    </div>
  )
}

export default Title
