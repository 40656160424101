import React from "react"
import "./style.scss"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Typewriter from "typewriter-effect"

import Separator from "../Separator"
import Glitch from "../Glitch"

const Icons = ({ icons }) =>
  icons.edges.map((value, index) => {
    return (
      <img
        src={value.node.childImageSharp.fluid.src}
        className={`animated fadeIn move-${
          Math.floor(Math.random() * 10) % 2 === 0 ? "up" : "down"
        } float-image`}
        style={{
          left: `${index * 9.7 + 1}%`,
          bottom: `${Math.random() * 10 + (index % 2 === 0 ? 0 : 85)}%`,
        }}
        alt="shape"
        key={index}
      />
    )
  })

const Left = ({ data }) => (
  <div
    className="columns is-flex is-vcentered is-centered has-background-primary is-gapless"
    style={{ height: "100%" }}
  >
    <div className="container pl-3 is-vcentered is-centered left-hero">
      <Icons icons={data.icons} />
      <div className="section-header is-vcentered">
        <Separator />
        <h4 className="is-size-4">{"Hello, we're"}</h4>
      </div>
      <Glitch text="Bruco Rapido" />
      <Typewriter
        options={{
          strings: ["The Software House"],
          autoStart: true,
          loop: true,
        }}
      />
    </div>
  </div>
)

const Right = ({ data }) => {
  const imageData = data.person.childImageSharp.fluid
  return (
    <Img
      fluid={imageData}
      style={{
        objectFit: "contain",
        overflow: "hidden",
        height: "100%",
        width: "100%",
      }}
      className="image-container"
    />
  )
}

const Main = ({ data }) => {
  return (
    <div className="container">
      <div
        className="columns is-gapless"
        style={{ height: `100vh`, overflow: "hidden" }}
      >
        <div className="column">
          <Left data={data} />
        </div>
        <div className="column">
          <Right data={data} />
        </div>
      </div>
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        person: file(relativePath: { eq: "person.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxHeight: 900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        icons: allFile(
          filter: {
            extension: { regex: "/(png)/" }
            relativeDirectory: { eq: "icons" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Main data={data} />}
  />
)
