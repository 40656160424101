import React, { useCallback, useState } from "react"
import SectionHeader from "../SectionHeader"
import Logo from "../Logo"
import { Link } from "gatsby"
import { useToasts, ToastProvider } from "react-toast-notifications"
import * as EmailValidator from "email-validator"

const Form = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    agreement: false,
  })
  const [error, setError] = useState(false)
  const { addToast } = useToasts()

  const check = useCallback(val => {
    if (error && !val) {
      return false
    } else {
      return true
    }
  })
  const emailCheck = useCallback(email => {
    if (error && !EmailValidator.validate(email)) {
      return false
    } else {
      return true
    }
  })

  const submit = useCallback(e => {
    e.preventDefault()
    if (
      formState.name === "" ||
      formState.email === "" ||
      formState.message === "" ||
      formState.agreement === false
    ) {
      setError(true)
      return
    } else {
      setError(false)
    }

    fetch("https://submit-form.com/x5FNve-2oaEqce48wCSeD", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ...formState,
      }),
    }).then(res => {
      if (res.ok) {
        setFormState({
          message: "",
          email: "",
          name: "",
          phone: "",
          agreement: false,
        })
        addToast("Message sent", { appearance: "success" })
      }
    })
  })

  return (
    <form onSubmit={e => submit(e)}>
      <SectionHeader title="Get In Touch" mainText="Contact Us" />
      <div className="form-container is-size-7">
        <div className="line-text">
          <div className="form-group">
            <input
              type="text"
              className={`name ${check(formState.name) ? "" : "error"}`}
              placeholder="Name"
              onChange={e =>
                setFormState({ ...formState, name: e.target.value })
              }
              name="name"
              value={formState.name}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className={`email ${emailCheck(formState.email) ? "" : "error"}`}
              placeholder="Email"
              onChange={e =>
                setFormState({ ...formState, email: e.target.value })
              }
              name="email"
              value={formState.email}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="phone"
              placeholder="Phone"
              onChange={e =>
                setFormState({ ...formState, phone: e.target.value })
              }
              name="phone"
              value={formState.phone}
            />
          </div>
          <div className="form-group">
            <textarea
              className={`message ${check(formState.message) ? "" : "error"}`}
              placeholder="Message"
              onChange={e =>
                setFormState({ ...formState, message: e.target.value })
              }
              name="message"
              value={formState.message}
            ></textarea>
          </div>
          <div>
            <div className="form-agreement is-size-7">
              <input
                id="agreement"
                type="checkbox"
                onChange={e =>
                  setFormState({ ...formState, agreement: e.target.checked })
                }
                name="agreement"
                checked={formState.agreement}
              ></input>
              <label htmlFor="agreement">
                I agree to the processing of my personal data by Bruco Rapido,
                based in Poland, Wrocław, Dmowskiego 3/9 - for contact purpose.
                To change or remove your personal data from our database, write
                to us:
                <br />
                <span className="form-agreement-mail">
                  info@brucorapido.com
                </span>
              </label>
            </div>
            {check(formState.agreement) ? (
              ""
            ) : (
              <p style={{ color: "red" }}>consent required</p>
            )}
          </div>
          <div className="submit column is-one-third is-one-third-mobile">
            <button className="form-button is-full" type="submit">
              Send Message
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

const ContactUs = () => {
  return (
    <section className="section">
      <div className="columns is-marginless" style={{ height: "100%" }}>
        <div className="column is-half contact is-paddingless pr-5">
          <div className="columns is-flex is-vcentered is-centered is-gapless">
            <div className="contact-container">
              <ToastProvider>
                <Form />
              </ToastProvider>
              <div className="column is-one-quarter is-one-quarter-mobile is-paddingless padding">
                <Logo />
              </div>
              <p className="is-size-7 padding">
                Bruco Rapido sp. z o.o.
                <br />
                Dmowskiego 3/9 Street, 50-203 Wrocław
                <br />
                NIP: 8982257181
              </p>
              <p className="is-size-7 padding">
                © Copyright 2020 Bruco Rapido, All rights reserved.{" "}
                <Link to={"/privacypolicy"} className="link">
                  Privacy policy
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="column is-half is-paddingless">
          <iframe
            title="map"
            width="100%"
            height="100%"
            src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;q=9/3%20Dmowskiego%20Street,%20Wroc%C5%82aw,%20Poland+(Bruco%20Rapido)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          />
        </div>
      </div>
    </section>
  )
}

export default ContactUs
