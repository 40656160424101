import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Brands from "@fortawesome/free-brands-svg-icons"
import * as Solids from "@fortawesome/free-solid-svg-icons"
import SectionHeader from "../SectionHeader"

const Service = ({ title, subtitle, icons }) => {
  return (
    <div className="column is-one-third">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">{title}</h1>
          <h2 className="subtitle">{subtitle}</h2>
          <div className="level is-mobile">
            <div className="level-left">
              {icons &&
                icons.map((icon, index) => (
                  <div className="level-item" key={index}>
                    <FontAwesomeIcon icon={icon} size="lg" />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default () => (
  <section className="section py-6">
    <SectionHeader title="Technology" mainText="What we do" />
    <div className="columns is-multiline">
      <Service
        title="Web Development"
        subtitle="
          Fast, scalable, responsive, web applications is what we're proficient in. All the solutions made with the cutting-edge technology.
                "
        icons={[Brands.faReact, Brands.faHtml5, Brands.faJs]}
      />
      <Service
        title="Mobile Development"
        subtitle="
Custom made apps for iOS/Android are the best way to be always with your clients, saying more precisely, in their pockets. 
                        "
        icons={[
          Brands.faApple,
          Brands.faAndroid,
          Brands.faReact,
          Brands.faSwift,
        ]}
      />
      <Service
        title="Robotic Process Automation"
        subtitle="
Save your precious time and let the robots do their robotic job - we manage the whole army of them, they're ready for support.
                                "
        icons={[Brands.faPython, Brands.faNode]}
      />
      <Service
        title="Design"
        subtitle='
          Each single detail matters, and may have crucial impact on the overall product "look & feel" - we know all the design tricks.
                                        '
        icons={[Brands.faSketch]}
      />
      <Service
        title="Outsourcing"
        subtitle="
We use state of the art technology on our daily basis - supporting you will be our pleasure, no matter of the on/near/off-shore model.
                                                "
        icons={[Solids.faGlobeAmericas]}
      />
      <Service
        title="SaaS Cloud solutions"
        subtitle="
Let us deliver your software on clients demand via the best known platforms - it's an easy way to share your idea with the World. 
                                                        "
        icons={[Brands.faDocker, Brands.faNode]}
      />
    </div>
  </section>
)
