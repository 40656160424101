import React from "react"
import Title from "./Title"
import Baffle from "../Baffle"

const SectionHeader = ({ title, mainText }) => {
  return (
    <div>
      <Title title={title} />
      <span className="section-subtitle">
        <Baffle
          text={mainText}
          revealDuration={500}
          revealDelay={500}
          parentMethod={() => {}}
          callMethodTime={1100}
        />
      </span>
    </div>
  )
}

export default SectionHeader
