import React from "react"

import "../style/style.scss"
import Layout from "../components/layout"
import Hero from "../components/Hero"
import AboutUs from "../components/AboutUs"
import Technologies from "../components/Technologies"
import ContactUs from "../components/ContactUs"
import CookieConsent from "react-cookie-consent"
import Helmet from "../components/Helmet"

const IndexPage = () => (
  <Layout>
    <Helmet />
    <Hero />
    <AboutUs />
    <Technologies />
    <ContactUs />
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="gatsby-gdpr-google-analytics"
      buttonClasses="cookie-accept-button"
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  </Layout>
)

export default IndexPage
