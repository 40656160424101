import React from "react"
import SectionHeader from "../SectionHeader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin"
import Particles from "react-particles-js"
import handleViewport from "react-in-viewport"

const About_Particles = () => {
  return (
    <Particles
      className="particles"
      params={{
        particles: {
          number: {
            value: 100,
            density: {
              enable: false,
              value_area: 5000,
            },
          },
          line_linked: {
            enable: true,
            opacity: 0.5,
          },
          size: {
            value: 1,
          },
        },
        interactivity: {
          events: {
            onhover: {
              enable: true,
              mode: "repulse",
            },
          },
        },
        retina_detect: true,
      }}
    />
  )
}
const AboutParticles = handleViewport(About_Particles)

const AboutUs = () => {
  return (
    <section className="section">
      <div className="columns has-full-height">
        <div className="column is-half has-full-height has-text-white about-us py-6">
          <div className="columns">
            <div className="is-11 column">
              <SectionHeader
                title="About us"
                mainText="We provide sustainable technology"
              />
              <br />
              <div className="about-us-list">
                <h2 className="subtitle">
                  We aim to deliver only what is necessary and sustainable. Goal
                  is always the same: to provide business value, by offering
                  your company:
                </h2>
                <br />
                <ul>
                  <div className="columns is-multiline">
                    <li className="column is-half subtitle">
                      Tailored software{" "}
                    </li>
                    <li className="column is-half subtitle">Top talents </li>
                    <li className="column is-half subtitle">Clean code</li>
                    <li className="column is-half subtitle">
                      Business understanding
                    </li>
                    <li className="column is-half subtitle">Secured content</li>
                    <li className="column is-half subtitle">Tommorow today</li>
                  </div>
                </ul>
              </div>
              <div>
                <span className="is-size-4">
                  Check our updates on &nbsp;
                  <span className="is-size-3">
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="about-us-icon"
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/company/bruco-rapido/"
                        )
                      }
                    />
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-half has-background-primary is-hidden-mobile">
          <AboutParticles />
        </div>
      </div>
    </section>
  )
}

export default AboutUs
